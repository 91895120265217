<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Bakım"
            icon="mdi-spray-bottle"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-maintenance')"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'communication.maintenances.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.maintenance_type="{ item }">
          <router-link
            v-if="item.maintenance_type_id && can('see-maintenance-type')"
            :to="{
              name: 'other-definitions.types.maintenance-types.show',
              params: { id: item.maintenance_type_id },
            }"
          >
            {{ item.maintenance_type }}
          </router-link>
          <template v-else>
            {{ item.maintenance_type }}
          </template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.child_maintenance_type="{ item }">
          <router-link
            v-if="item.child_maintenance_type_id && can('see-maintenance-type')"
            :to="{
              name: 'other-definitions.types.maintenance-types.show',
              params: { id: item.child_maintenance_type_id },
            }"
          >
            {{ item.child_maintenance_type }}
          </router-link>
          <template v-else>
            {{ item.child_maintenance_type }}
          </template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.subject="{ value }">
          <rs-table-cell-string :value="value" :limit="20" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.reminded_at="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_closed="{ item, value }">
          <rs-table-cell-boolean
            :value="value"
            :title="
              item.closed_at ? new Date(item.closed_at).toLocaleString() : ''
            "
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string :limit="20" :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.provider_name="{ item }">
          <router-link
            v-if="item.provider_id"
            :to="{
              name: 'definitions.cluster-providers.show',
              params: { id: item.provider_id },
            }"
          >
            {{ item.provider_name }}
          </router-link>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text> -->
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  name: "MaintenanceList",
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["companyId", "clusterId", "reminderPeriodList", "typeList"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
    companyId() {
      this.loadList();
    },
  },
  data() {
    return {
      titleBarAttrs: {
        exportUrl: "maintenance-schedules",
        exportParams: this.getParams,
      },
      options: {
        sortBy: ["reminded_at"],
        sortDesc: [false],
      },
      search: {
        is_closed: false,
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-maintenance"),
        },
        {
          text: "TYA",
          value: "cluster_name",
          searchable: "text",
          multiple: true,
          hide: () => this.clusterId,
        },
        {
          text: "Konu",
          value: "subject",
          searchable: "text",
        },
        {
          text: "Tarih",
          value: "reminded_at",
          searchable: "date",
        },
        {
          text: "Bakım Türü",
          value: "maintenance_type",
          searchable: "text",
        },
        {
          text: "Bakım Tipi",
          value: "child_maintenance_type",
          searchable: "text",
        },
        {
          text: "Bakımı Giderecek",
          value: "provider_name",
          searchable: "text",
        },
        {
          text: "Periyot",
          value: "reminder_period",
          searchable: "multiselect",
          options: () => this.reminderPeriodList,
          searchValue: "reminder_period_id",
        },
        {
          text: "Kapanmış",
          value: "is_closed",
          searchable: "yesNo",
          align: "center",
          width: "80px",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["updateMaintenanceCount", "updateMaintenanceRecentList"]),
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      // params.order_by = "id";

      params.is_reminded = true;

      return params;
    },
    loadList() {
      if (this.isLoading) {
        this.resetTable();
        return false;
      }

      this.resetTable();
      this.isLoading = true;

      const params = this.getParams();

      this.$api
        .query("maintenance-schedules", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;

            this.updateMaintenanceRecentList(response.data.data);
            this.updateMaintenanceCount(response.data.meta.total);
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.isLoading || this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "communication.maintenances.edit",
        params: {
          id: this.selectedItems[0].id,
        },
      });
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
};
</script>
